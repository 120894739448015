.main-container {
    width: 100%;
    float: left
}

.main-component {
    float: left;
}

.btn-container {
    margin: 5px;
    text-align: center;
}

.btn {
    margin: 5px;
}

.btn-afterstart {
    width: 100%;
}

.info-container {
    margin: 5px;
    padding-right: 50px;
    float: right;
}


/* Hide scrollbar for Chrome, Safari and Opera */

.infoContainer::-webkit-scrollbar {
    display: none;
}


/* Hide scrollbar for IE, Edge and Firefox */

.infoContainer {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}