/* .doc-type-card-header {
    border: 1px solid black;
    border-radius: 5px;
} */

.accordion-btn {
    width: 100%;
    text-align: left;
    box-shadow: 0px 2.5px 10px #888888;
}

.accordion-btn:hover {
    box-shadow: 0px 2.5px 10px #cacaca;
}

.arrow {
    width: 20px;
}

.list-group-item {
    border: none;
    margin: 10px 0;
    box-shadow: 0px 2.5px 10px #888888;
    cursor: pointer;

}

.list-group-item:hover {
    box-shadow: 0px 2.5px 10px #cacaca;
}