.rectangle {
    /* border: 1px solid #FF0000; */
    position: absolute;
}

.crop-overlay {
    position: absolute;
    cursor: crosshair;
    background-color: rgba(218, 218, 218, 0.5);
}

.loading-overlay {
    position: absolute;
    background-color: rgba(218, 218, 218, 0.5);
}

#img-preview {
    z-index: -1;
}