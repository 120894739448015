.active-template-container {
    margin-top: 25px;
    padding: 5px 0;
    z-index: -100;
}

.template-item {
    text-align: center;
}

.modal-content {
    touch-action: none;
    user-select: none;
    transform: translate(0px, 0px);
}

.add-new-roi-modal-header:hover {
    background-color: rgb(233, 233, 233);
}